import React, { Fragment } from "react";
import UF_Content from "../../../nix_components/components/UF_Content";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: this.props.table,
      title: this.props.title,
    };
  }

  gridProps = {
    fields: this.props.fields,
  };

  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props) != JSON.stringify(nextProps)) {
      this.gridProps.fields = nextProps.fields;
      this.setState({
        table: nextProps.table,
        title: nextProps.title,
      });
    }

    return true;
  };

  render() {
    const { table, title } = this.state;
    return (
      <Fragment>
        <UF_Content
          table={table}
          title={title}
          gridProps={{ ...this.gridProps }}
          onSave={this.onSave}
          buttons={this.buttons}
          edit={false}
          export={false}
          delete={false}
          add={false}
          sync={false}
        />
      </Fragment>
    );
  }
}
