import React, {Fragment} from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {ExpandLess, ExpandMore} from "@mui/icons-material"
import {Collapse} from "@mui/material"
import List from "@mui/material/List"
import ListItem from "./ListItem"
import withRouter from "../../../../components/withRouter"
import "../menu.scss"

function ListItemDropDown(props) {
    const [open, setOpen] = React.useState(props.open)
    return (
        <Fragment>
            <ListItemButton onClick={() => setOpen(!open)} sx={props.sx}
                            className={props.active ? open === false ? 'menu_item menu_item_active' : 'menu_item' : 'menu_item'}>
                <ListItemIcon sx={{color: props.active ? open === false ? '#FFF' : '#A5A7AF' : '#A5A7AF'}}>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={props.title}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        props.children && props.children ?
                            props.children.map((child, index) => {
                                if ((child.read_only === true || child.full_access === true) || (!child.hasOwnProperty('full_access') && !child.hasOwnProperty('read_only'))) {
                                    return (
                                        <Fragment key={index}>
                                            {
                                                child.children && child.children.length ?
                                                    <ListItemDropDown
                                                        active={child.active}
                                                        children={child.children}
                                                        title={child.title}
                                                        sx={{pl: child.level * 3}}
                                                        icon={child.icon}
                                                    /> :
                                                    <ListItem
                                                        active={child.active}
                                                        icon={child.icon}
                                                        title={child.title}
                                                        sx={{pl: child.level * 3}}
                                                        onClick={() => props.router.navigate(`/${child.url}`)}
                                                    />
                                            }
                                        </Fragment>

                                    )
                                }
                                return null
                            }) : null
                    }
                </List>
            </Collapse>
        </Fragment>
    )
}

export default withRouter(ListItemDropDown)