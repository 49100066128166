/* eslint-disable react/jsx-pascal-case */
import React, { Fragment } from "react";
import Nx_Grid from "./nx_grid";
import Nx_Filters from "./filters/nx_filters";
import "./nx_grid.scss";
import Grid from "../ui_components/layouts/Grid/Grid";

class Nx_Grid_Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      fields: this.props.gridProps?.fields,
      activeFilters: {},
      lastUpdate: 0,
    };
  }

  shouldComponentUpdate = (nextProps) => {
    if (
      this.props.gridProps.fields.length != nextProps.gridProps.fields.length
    ) {
      this.setState({ fields: nextProps.gridProps.fields });
    }
    return true;
  };

  render() {
    return (
      <Fragment>
        <Grid className="uf_content_main" style={{ gap: "15px" }}>
          <Grid
            style={{
              width: "100%",
              height: "auto",
              gridAutoFlow: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Nx_Filters
              table={this.props.table}
              onFiltersChange={(activeFilters) =>
                this.setState({
                  activeFilters,
                  lastUpdate: new Date().getTime(),
                })
              }
              initialSearch={this.props.initialSearch}
            />
            <Grid>{this.props.renderTools()}</Grid>
          </Grid>

          <Nx_Grid
            rowHeight={this.props.rowHeight}
            table={this.props.table}
            additional_data={this.props.additional_data}
            filters={this.state.activeFilters}
            onDataItemsLoad={this.props.onDataItemsLoad}
            syncData={this.props.syncData}
            attendeeType={this.props.attendeeType}
            customFiltering={this.props.customFiltering}
            registrationType={this.props.registrationType}
            id={this.props.id}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            lastUpdate={
              this.props.lastUpdate > this.state.lastUpdate
                ? this.props.lastUpdate
                : this.state.lastUpdate
            }
            fetchData={this.props.fetchData}
            buttons={this.props.buttons}
            popOverButtons={this.props.popOverButtons}
            rowDoubleClick={this.props.rowDoubleClick}
            onRowClick={this.props.onRowClick}
            onAdd={this.props.onAdd}
            fields={this.state.fields}
          />
        </Grid>
      </Fragment>
    );
  }
}

export default Nx_Grid_Filters;
