import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "../../nix_components/ui_components/components/Menu/Menu.js";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Nx_Loader from "../../nix_components/components/nx_loader";
import GroupIcon from "@mui/icons-material/Group";
import { NxBreadcrumbs } from "../../nix_components/ui_components/components/NxBreadcrumbs.jsx";
import withRouter from "../../nix_components/components/withRouter.js";
import Page from "./components/Page.jsx";
import NxIconButton from "../../nix_components/ui_components/controls/nx_icon_button.jsx";
import Nx_Button from "../../nix_components/ui_components/controls/nx_button.js";

class Secure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: true,
      loaded: false,
      initializing: true,
    };

    const data_structure = global.datastructure;
    const views = Object.keys(data_structure).filter((key) =>
      key.toLowerCase().includes("view_bo")
    );

    this.menuItems = this.generateMenuItems(views);
  }

  generateMenuItems = (views) => {
    const menuItems = [];
    views.forEach((view, key) => {
      const title = view.replace("View_BO_", "");
      const table = view.toLowerCase();
      const fields = Object.keys(global.datastructure[view].Fields);
      const item = {
        title: title,
        level: 1,
        url: `/${title.toLowerCase()}`,
        icon: <GroupIcon />,
        element: () => {
          return <Page key={key} table={table} fields={fields} title={title} />;
        },
      };
      menuItems.push(item);
    });
    return menuItems;
  };

  componentDidMount = async () => {
    this.setState({ loaded: true });
  };

  handleMenu = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  renderElement = () => {
    this.routes = this.renderRoutes();
    return (
      <Grid
        fullScreen={true}
        style={{
          backgroundColor: "#F8FAFB",
          gridTemplateColumns: `${this.state.showMenu ? "321px auto" : "auto"}`,
          overflow: "hidden",
          minHeight: "925px",
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: "1000",
          }}
          onClick={this.handleMenu}
        >
          {this.state.showMenu ? (
            <ArrowBack
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          ) : (
            <MenuIcon
              style={{
                color: `${
                  this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                }`,
              }}
            />
          )}
        </IconButton>
        <Grid
          style={{
            width: `${this.state.showMenu ? "321px" : "0px"}`,
            transition: `width 1s ease, opacity 1s ease`,
            backgroundColor: "rgba(30, 30, 30, 0.70)",
            overflow: "hidden",
            overflowY: "auto",
            opacity: `${this.state.showMenu ? "1" : "0"}`,
          }}
          hidden={!this.state.showMenu}
        >
          <Menu items={this.menuItems} />
        </Grid>
        <Grid style={{ gridTemplateRows: "80px auto" }}>
          <Grid style={{ gridTemplateColumns: "90% auto" }}>
            <NxBreadcrumbs router={this.props.router} />
            <div
              style={{
                display: "flex",
                padding: "20px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Nx_Button
                size="small"
                variant={"outlined"}
                label={"Log out"}
                onClick={this.logOut}
              />
            </div>
          </Grid>
          <Grid
            style={{ width: "100%", height: "100%", justifySelf: "center" }}
          >
            <Routes>{this.routes}</Routes>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  renderRoutes = () => {
    if (this.menuItems && this.menuItems.length) {
      return this.menuItems.map((menuItem, index) => {
        if (menuItem.hasOwnProperty("children")) {
          return menuItem.children.map((child, key) => {
            let currentMenuItem = menuItem.children.find(
              (item) => item.url === child.url
            );
            return (
              <Route
                path={`/${currentMenuItem.url.split("/")[2]}`}
                key={key}
                element={currentMenuItem.element()}
              />
            );
          });
        } else {
          let currentMenuItem = this.menuItems.find(
            (item) => item.url === menuItem.url
          );
          const split = currentMenuItem.url.split("/");
          let tempUrl = "";
          if (split.length > 2) {
            split.slice(0, 1);
            tempUrl = split.join("/");
          } else {
            tempUrl = split[1];
          }
          return (
            <Route
              path={`/${tempUrl}`}
              key={index}
              element={currentMenuItem.element()}
            />
          );
        }
      });
    } else return null;
  };

  render() {
    if (!this.state.loaded) {
      return <Nx_Loader show={!this.state.loaded} />;
    } else {
      return (
        <Fragment>
          <div>{this.renderElement()}</div>
        </Fragment>
      );
    }
  }
}

export default withRouter(Secure);
