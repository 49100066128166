import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Nx_Button from '../controls/nx_button';

const NxConfirm = (props) => {

    return (
        <Dialog
        open={props.show}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Nx_Button onClick={props.onClose} label={"Cancel"} variant={"outlined"}/>
          <Nx_Button onClick={props.onClick} label={"Yes"} variant={"contained"}/>
        </DialogActions>
      </Dialog>
    )
}

export default NxConfirm