import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import "./controls.scss"


export default function Nx_image(props) {

    const image = React.useRef()
    const [style, setStyle] = useState({})

    useEffect(() => {
        const style = props.style ? Object.assign({}, props.style) : {}
        setStyle(style)
    }, [])

    return (
        <img src={props.source} style={style} alt={props.alt} ref={image} onClick={props.onClick}/>
    )

}

Nx_image.propTypes = {
    source: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func
}