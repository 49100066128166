import data_types from "./data_types";
import React from "react";
import { setValidations, validators } from "./data_validators";

const data_configs = {};
const static_configs = {};

const parseDataType = (name, type) => {
  if (type.indexOf("char") > -1) {
    return data_types.string;
  }

  if (type.indexOf("enum") > -1) {
    return data_types.string;
  }

  switch (type) {
    case "timestamp":
      return data_types.time;
    case "date":
      return data_types.date;
    case "datetime":
      return data_types.datetime;
    case "time":
      return data_types.time;
    case "int":
      return data_types.number;
    case "tinyint(1)":
      return data_types.boolean;
    case "json":
      return data_types.json;
    case "text":
    case "longtext":
      return data_types.string;
  }

  if (
    type.indexOf("int") > -1 ||
    type.indexOf("float") > -1 ||
    type.indexOf("long") > -1 ||
    type.indexOf("decimal") > -1
  ) {
    return data_types.number;
  }

  console.error(`New datatype for field : ${name} that is type : ${type}`);
  return data_types.string;
};

const capitalizeFirstLetter = (string) => {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const generateFieldsName = (label) => {
  if (label.includes("_")) {
    return `${capitalizeFirstLetter(
      label.split("_")[0]
    )} ${capitalizeFirstLetter(label.split("_")[1])}`;
  } else {
    return capitalizeFirstLetter(label);
  }
};

const handleCustomRenderCell = (field) => {
  if (!field.hasOwnProperty("renderCell")) {
    if (field.Field.toLowerCase().includes("email")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`mailto:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
    if (field.Field.toLowerCase().includes("phone")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`tel:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
  }
};

const data_structure = global.datastructure;

Object.keys(data_structure).forEach((table) => {
  data_configs[table.toLowerCase()] = {};
  let fields = Object.keys(data_structure[table].Fields);
  const data_stucture = data_structure[table].Fields;
  let curConfig = data_configs[table.toLowerCase()];
  curConfig.fields = {};
  curConfig.fieldsArr = [];
  if (static_configs[table]) {
    curConfig.ignoreValidation = static_configs[table].ignoreValidation;
    curConfig.sorting = static_configs[table].sorting;
    curConfig.filters = static_configs[table].filters;
    curConfig.additionalData = static_configs[table].additionalData;
    curConfig.id = static_configs[table].id;
  }
  fields.forEach((fieldName) => {
    const field = data_stucture[fieldName];
    let curField = {
      ...field,
      field: field.Field,
      name: field.Field,
      label: generateFieldsName(field.Field),
      nullable: field.Null != "NO",
      datatype: parseDataType(field.Field, field.dataType),
    };

    handleCustomRenderCell(curField);

    if (static_configs[table] && static_configs[table].fields[field["Field"]]) {
      curField = Object.assign(
        curField,
        static_configs[table].fields[field["Field"]]
      );
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.boolean
    ) {
      curField.defaultValue = false;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      (curField.datatype == data_types.date ||
        curField.datatype == data_types.datetime)
    ) {
      let date = new Date();
      curField.timezone = "UTC";
      curField.defaultValue = `${date.getUTCFullYear()}-${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    }
    if (curField.datatype == "time") {
      curField.timezone = "UTC";
    }
    if (curField.field == "updated_at") {
      curField.defaultValue = null;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.color
    ) {
      curField.defaultValue = "#000";
    }
    curConfig.fields[field["Field"]] = curField;
    curConfig.fieldsArr.push(curField);
  });
  setValidations(data_configs[table.toLowerCase()]);
});

export default data_configs;
