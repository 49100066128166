import React from "react";
import { IconButton } from "@mui/material";
import "./controls.scss";

function NxIconButton(props) {
  const style = props.style ? props.style : {};
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <IconButton
      aria-label="edit"
      style={style}
      color="default"
      onClick={onClick}
    >
      {props.children}
    </IconButton>
  );
}

export default NxIconButton;
