const referenceTypes = {
  local: "local",
  remote: "remote",
};

const supplierStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const supplierLocationStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const supplierContactStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const customerStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const customersMarginTypes = {
  text: "Text",
  trade: "Trade",
};

const customerContactsStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const customerLocationsStatuses = {
  active: "Active",
  inactive: "Inactive",
};

const currenciesStatusActive = {
  yes: "yes",
  no: "no",
};

const bookTypes = {
  text: "Text",
  trade: "Trade",
  both: "Both",
};

const customerBookTypes = {
  text: "Text",
  trade: "Trade",
  both: "Both",
};

const calculationTypes = {
  per_book: "Per Book",
  per_kg: "Per kg",
};

const quantityRestictionIntervals = {
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
};

const leadIntervals = {
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
};

const referenceConfigs = {
  countries: {
    id: "id",
    label: "country",
    type: referenceTypes.remote,
    table: "countries",
  },
  publishers: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "publishers",
    filters: {
      status: "1",
    },
  },
  customers: {
    id: "id",
    label: "customer_name",
    type: referenceTypes.remote,
    table: "customers",
    filters: {},
  },
  currencies: {
    id: "id",
    label: "currency_code",
    type: referenceTypes.remote,
    table: "currencies",
    filters: {},
  },
  currencies_active: {
    id: "id",
    label: "currency_code",
    type: referenceTypes.remote,
    table: "currencies",
    filters: {
      is_active: 1,
    },
  },
  supplierStatuses: {
    type: referenceTypes.local,
    source: supplierStatuses,
  },
  currenciesStatusActive: {
    type: referenceTypes.local,
    source: currenciesStatusActive,
  },
  bookTypes: {
    type: referenceTypes.local,
    source: bookTypes,
  },
  calculationTypes: {
    type: referenceTypes.local,
    source: calculationTypes,
  },
  leadIntervals: {
    type: referenceTypes.local,
    source: leadIntervals,
  },
  quantityRestictionIntervals: {
    type: referenceTypes.local,
    source: quantityRestictionIntervals,
  },
  supplierLocationStatuses: {
    type: referenceTypes.local,
    source: supplierLocationStatuses,
  },
  supplierContactStatuses: {
    type: referenceTypes.local,
    source: supplierContactStatuses,
  },
  customerStatuses: {
    type: referenceTypes.local,
    source: customerStatuses,
  },
  customerBookTypes: {
    type: referenceTypes.local,
    source: customerBookTypes,
  },
  customerContactsStatuses: {
    type: referenceTypes.local,
    source: customerContactsStatuses,
  },
  customerLocationsStatuses: {
    type: referenceTypes.local,
    source: customerLocationsStatuses,
  },
  customersMarginTypes: {
    type: referenceTypes.local,
    source: customersMarginTypes,
  },
};

class DataReferences {
  references = {};
  reference_configs = referenceConfigs;
  data_provider = null;

  constructor(data_provider) {
    this.data_provider = data_provider;

    Object.keys(referenceConfigs).forEach((key) => {
      if (referenceConfigs[key].type == referenceTypes.local) {
        this.references[key] = [];
        Object.keys(referenceConfigs[key].source).forEach((item) => {
          this.references[key].push({
            id: referenceConfigs[key].source[item],
            label: referenceConfigs[key].source[item],
          });
        });
      }
    });
  }

  search = (reference, search) => {
    const data = this.references[reference];
    if (data) {
      let tempData = data.map((ref) => {
        if (ref.label.toLowerCase().includes(search.toLowerCase())) {
          return `'${ref.id.toString()}'`;
        } else {
          return null;
        }
      });
      tempData = tempData.filter((ref) => ref !== null);
      return tempData;
    }
  };

  getReferenceLabel = (reference, value) => {
    let label = "";
    if (reference !== null && this.references[reference] && value !== null) {
      label = this.references[reference].find((ref) => ref.id == value)
        ? this.references[reference].find((ref) => ref.id == value).label
        : "";
    }
    return label;
  };

  get = (reference, callBack, force = false) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      setTimeout(() => {
        callBack(this.references[reference]);
      }, 100);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};
      const table = referenceConfigs[reference].table;
      this.data_provider.get(table, filters, (data) => {
        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });
        callBack(this.references[reference]);
      });
    }
  };
}

export default DataReferences;
