import React, {useState, useEffect, Fragment} from 'react'
import Grid from "../../../ui_components/layouts/Grid/Grid"
import MenuItem from "@mui/material/MenuItem/MenuItem"
import "../nx_filters.scss"


function Nx_filter_section(props) {

    const handleMenuItemClick = (item, field) => {
        item.selected = !item.selected
        const tempValue = props.filter.items.filter(item => item.selected === true).map(item => item.id);
        props.onChange(field, tempValue)
    }

    return (
        <Fragment key={new Date().getTime()}>
            <Grid hidden={props.hidden} key={new Date().getTime()}>
                {
                    props.filter && props.filter.items && props.filter.items.length ?
                        props.filter.items.map((obj, index) => {
                            return (
                                <MenuItem className={"filters_card"} key={index} value={obj.id} selected={obj.selected}
                                          onClick={() => handleMenuItemClick(obj, props.filter.field)}>
                                    {obj.label === 'Pending confirmation' ? global.UF.EVENT_SETTINGS.PENDING_CONFIRMATION_LABEL : obj.label}
                                </MenuItem>
                            )
                        }) : <div>There are no options for this filter</div>
                }
            </Grid>
        </Fragment>

    )
}



export default (Nx_filter_section)